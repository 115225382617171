
  <div class="about">
    <img style="width:100%" src="https://qiniu.qsmiaoyu180.cn/website/gywm%402x.png" alt="">
    <img class="gsjs" src="https://qiniu.qsmiaoyu180.cn/website/gsjs%402x.png" alt="</img>">
    <div class="box">
      <p>
        深圳市妙语教育科技有限公司（妙语教育）成立于2018年12月，是由一批计算机应用科学技术骨干，多年在一线从事语言教育的专家、名师，以及资深的互联网行业从业人员一起创办。公司股东有小白鸽教育集团、中传世纪文化、科大讯飞/淘云科技这样的知名企业。
      </p>
      <p>多年来妙语教育专注于中小学生语言能力培养、提升课程体系的开发，以及中小学生普通话水平测评技术的研究。采用先进的人工智能技术，结合自主开发的语言训练课程，为广大中小学生及家长提供先进的教学服务。</p>
    </div>
    <div class="box box2">
      <div class="box2_left">
        <img class="gywm_01" src="https://qiniu.qsmiaoyu180.cn/website/gywm_01%402x.png" alt="">
        <p>早在两千多年前，古人就明确指出了语言表达的重要性。然而由于中国“农耕文明”的特质，中国在语言能力提升和训练方面的重视度一直不够。相比较西方发达国家而言，大多数国人的语言表达能力是偏弱的。</p>
        <p>
          随着时代的发展，中国已成为世界第二大经济体，“一带一路”战略的实施，提升国人的语言表达能力和加快汉文化传播已刻不容缓。为此，教育部和国家语委正式制定了《国家中长期语言文字事业改革和发展规划纲要》，在这个文件里明确提出的主要任务如下：
        </p>
        <p class="none">（一）大力推广和普及国家通用语言文字；</p>
        <p class="none">（二）推进语言文字规范化标准化信息化建设；</p>
        <p class="none">（三）加强语言文字社会应用监督检查和服务；</p>
        <p class="none">（四）提高国民语言文字应用能力；</p>
        <p class="none">（五）科学保护各民族语言文字；</p>
        <p class="none">（六）弘扬传播中华优秀文化；</p>
        <p class="none">（七）加强语言文字法制建设。</p>
      </div>
      <img class="sz" src="https://qiniu.qsmiaoyu180.cn/website/sz%402x.png" alt="">

    </div>
    <div class="box3">
      <div class="boxTitle">具体对应到语言能力方面，国家也逐步开展了多项具体措施</div>
      <div class="box3_list">
        <div class="box3_item">
          <div class="box3_num">01</div>
          <p class="none1">《普通话水平等级测试》，规定了普通话的6个等级及测试标准，测试合格由国家语委颁发证书</p>
        </div>
        <div class="box3_item">
          <div class="box3_num">02</div>
          <p class="none1">《汉语水平等级测试》，简称HSK。这是面向国外友人进行的汉语水平等级测试标准</p>
        </div>
        <div class="box3_item">
          <div class="box3_num">03</div>
          <p class="none1">幼儿园明确了五大教学方向，分别是：健康、艺术、语言、社会、科学。其中对于“语言”教学要求最为具体</p>
        </div>
        <div class="box3_item">
          <div class="box3_num">04</div>
          <p class="none1">
            2019年全面修订《语文》教材并统一为部编版，明确指出“语文=语言+文化”，比如，考试有听力题，每天的课后作业有“熟读”和“背诵”，课堂上有“口语交际”等等，这些都是历史性的改变，是前所未有的</p>
        </div>
      </div>
    </div>
    <div class="box4">
      <img class="dt" src="https://qiniu.qsmiaoyu180.cn/website/dt%402x.png" alt="">
      <div class="box2_left" style="paddingTop:50px">
        <div class="topic">尤其是针对青少年群体的语言能力提升方面，国家更是开始了科学细致的系统性工程，具体如下：</div>
        <div class="feature">
          <div class="feature_item">
            <div class="feature_num">01</div>
            <p class="feature_content">
              2019年开始，教育部和国家语委选定了12个省市（北京、甘肃、贵州、黑龙江、湖北、江西、四川、河北、上海、长沙、江苏）作为试点，进行中小学生普通话水平数据采集工作，连续两年。并且对这些采集的数据进行了详尽的分析；
            </p>
          </div>
          <div class="feature_item">
            <div class="feature_num">02</div>
            <p class="feature_content">
              选择长沙市和江苏省作为全面试点。其中长沙市正式将“语文口语测评”成绩纳入中考总分（满分20分），江苏省全省的3、5年级开展抽测；</p>
          </div>
          <div class="feature_item">
            <div class="feature_num">03</div>
            <p class="feature_content">
              综合上述12个省市的数据采集和实际测评结果，教育部和国家语委正式制定《中小学生普通话水平等级测试标准与测试大纲》，并于2022年3月28日正式颁布。明确指出该项测试工作是“十四五”期间的重点工作之一。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="course">
      <img class="fzlc" src="https://qiniu.qsmiaoyu180.cn/website/fzlc%402x.png" alt="">
      <img class="fzlc_x" src="../assets/img/about/fzlc_x@2x.png" alt="">
      <div class="courseList">
        <div class="courseItem">
          <div class="courseTime">2017年9月-2019年8月</div>
          <div class="courseContent">
            <p>完成教学体系设计、教材的编著，以及课程体系的搭建</p>
          </div>
        </div>
        <div class="courseItem">
          <div class="courseTime">2019年9月-2021年1月</div>
          <div class="courseContent" style="width:651px">
            <p>1.线下设立教学实验基地，检验课程产品的客户满意度，并持续优化； </p>
            <p>2.与国家语委/全球中文学习平台签署合作协议，获准对接中小学生普通话水平考试系统；</p>
            <p>3.组织具有一线语文教学经营的名师，编著线上教学的课程内容；</p>
          </div>
        </div>
        <div class="courseItem">
          <div class="courseTime">2021年3月</div>
          <div class="courseContent">
            <p>清声妙语APP-V1.0正式上线</p>

          </div>
        </div>
      </div>
    </div>
    <div class="honor">
      <img class="ryzs" src="https://qiniu.qsmiaoyu180.cn/website/ryzs%402x.png" alt="">
      <div class="honorList">
        <div class="honorItem">
          <img class="honorImg1" src="https://qiniu.qsmiaoyu180.cn/website/zs_01%402x.png" alt="">
          <div class="honorTime">
            <img class="sjx" src="../assets/img/about/sjx@2x.png" alt="">
            <div class="honorNum">2021年5月</div>

          </div>
          <p>清声妙语APP成功获软件著作权</p>
        </div>
        <div class="honorItem" style="margin: 0 3%">
          <img class="honorImg2" src="https://qiniu.qsmiaoyu180.cn/website/zs_02%402x.png" alt="</img>">
          <div class="honorTime">
            <img class="sjx" src="../assets/img/about/sjx@2x.png" alt="">
            <div class="honorNum">2022年</div>

          </div>
          <p>成功获得1项发明专利及3项外观专利</p>
        </div>
        <div class="honorItem">
          <img class="honorImg3" src="https://qiniu.qsmiaoyu180.cn/website/zs_03%402x.png" alt="</img>">

          <div class="honorTime">
            <img class="sjx" src="../assets/img/about/sjx@2x.png" alt="">
            <div class="honorNum">2022年9月</div>

          </div>
          <p>参加创新创业大赛，并成功晋级半决赛</p>
        </div>
        <div class="honorItem2">
          <img class="honorImg4" src="https://qiniu.qsmiaoyu180.cn/website/zs_04%402x.png" alt="">
          <div class="honorTime2">
            <img class="sjx sjx2" src="../assets/img/about/sjx@2x.png" alt="">
            <div class="honorNum">2020年1月</div>
            <p>清声妙语课程体系相关教材成功获得著作权登记</p>
          </div>

        </div>
        <div class="honorItem2" style="marginLeft:3%">
          <img class="honorImg5" src="https://qiniu.qsmiaoyu180.cn/website/zs_05%402x.png" alt="</img>">
          <div class="honorTime2">
            <img class="sjx sjx2" src="../assets/img/about/sjx@2x.png" alt="">
            <div class="honorNum">2021年9月</div>
            <p>iFLYTEK A.I. 开发者大赛晋级证书</p>
          </div>

        </div>
      </div>
    </div>
  </div>
